import React from 'react';
import { Helmet } from 'react-helmet';

import Section from '$ui/section';
import Heading from '$ui/heading';
import WhiteBox from '$ui/whiteBox';
import Point from '$ui/point';

const ConsumerRightPage = () => (
  <>
    <Helmet>
      <title>TheCRO &mdash; ConsumerRight</title>
    </Helmet>

    <main>
      <Section withAbstract={true}>
        <WhiteBox className>
          <Heading id='consumerRights' className='mt-9'>
            Objectives
          </Heading>

          <ul className='gap-x-20 gap-y-8 mt-18 tab-port:grid-cols-none'>
            <Point variant='primary'>RIGHT TO INFORMATION</Point>
            <p variant='primary' className='ml-2 mb-3 mt-2'>
              This right is meant to protect consumers against unfair trade
              practices. But there are many consumer goods in India that do not
              follow the standardized labeling convention. Take medicines for
              example. The pharmaceuticals are required to disclose potential
              side effects of their drugs and manufacturers should publish
              reports from independent product testing laboratories in order to
              facilitate a comparative analysis with the competitive product.
              But there is no such thing in practice. A consumer has the right
              to be informed about the quality, quantity, potency, purity,
              standard and price of goods or services.
            </p>
            <Point variant='primary'>RIGHT TO INFORMATION</Point>
            <p variant='primary' className='ml-2 mt-2 mb-3'>
              The main application of this right is in the healthcare,
              pharmaceuticals and food processing sector as these have direct
              impact on health. It aims to protect consumers against immoral
              practices by doctors, hospitals, pharmacies and the automobile
              industry. But in the absence of world-class product testing
              facilities in our country the right can, well, be termed as
              toothless Consumers have the right to be protected against
              marketing of goods and services which are hazardous to life and
              property.
            </p>
            <Point variant='primary'>RIGHT TO BE HEARD</Point>
            <p variant='primary' className='ml-2 mt-2 mb-3'>
              Although consumer courts are there to hear and resolve the
              complaints of a consumer, we still lack a substantial outlet where
              consumers can voice out their opinion. Having said that, there are
              many websites which aim to make sure that the voice of customers
              reach the corporate world. These websites let customers upload
              their grievances and file complaints. Every grievance filed
              gradually lessens the overall SCore of the product being
              criticized. If the new bill seeking to amend the present law is
              passed, a Central Consumer Protection Authority with investigative
              powers will be set up. Every consumer has a right to be heard and
              to be assured that his / her interests will receive due
              consideration at appropriate forums.
            </p>
            <Point variant='primary'>RIGHT T0 CONSUMER EDUCATION</Point>
            <p variant='primary' className='ml-2 mt-2 mb-3'>
              This right ensures that proper informational programs are in place
              that are easily accessible and help consumers make purchasing
              decisions. However, since most of the initiatives are taken by
              NGOs in India, there's a need for the government to step up its
              participation. It is the right of every Indian citizen to have
              education on matters regarding consumer protection as well as
              about her / his right.
            </p>
          </ul>
        </WhiteBox>
      </Section>
    </main>
  </>
);

export default ConsumerRightPage;
